import React from 'react';
import { MsalLogEntry } from '../shared/msalLogger';

interface MsalLogDisplayProps {
  logs: MsalLogEntry[];
}

export const MsalLogsTable: React.FC<MsalLogDisplayProps> = ({ logs }) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', fontFamily: 'monospace', fontSize: 'medium' }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'left', padding: '.25rem 1rem', borderBottom: '1px solid #ddd' }}>Timestamp</th>
          <th style={{ textAlign: 'left', padding: '.25rem 1rem', borderBottom: '1px solid #ddd' }}>Level</th>
          <th style={{ textAlign: 'left', padding: '.25rem 1rem', borderBottom: '1px solid #ddd' }}>CorrelationID</th>
          <th style={{ textAlign: 'left', padding: '.25rem 1rem', borderBottom: '1px solid #ddd' }}>Message</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, index) => (
          <tr key={index}>
            <td
              style={{
                padding: '.25rem 1rem',
                borderBottom: '1px solid #ddd',
                whiteSpace: 'nowrap',
              }}
            >
              {log.timestamp.toISOString()}
            </td>
            <td
              style={{
                padding: '.25rem 1rem',
                borderBottom: '1px solid #ddd',
                whiteSpace: 'nowrap',
              }}
            >
              {log.level}
            </td>
            <td style={{ padding: '.25rem 1rem', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap' }}>
              {log.correlationId ? <span> {log.correlationId}</span> : '-'}
            </td>
            <td style={{ padding: '.25rem 1rem', borderBottom: '1px solid #ddd', whiteSpace: 'balance' }}>
              {log.message}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
