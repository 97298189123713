import { FuryApplicationLink, FuryHeaderItem, FuryPage, FuryUserHeaderItem } from '@netfleets/fms-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../provider/AuthenticationProvider';
import { AppSwitcher } from './AppSwitcher/AppSwitcher';
import styles from './Header.css';
import LanguageModal from './LanguageModal';
import { NavigationBar } from './NavigationBar/NavigationBar';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import { NavigationMenuButton } from './NavigationMenu/NavigationMenuButton';
import CustomUserMenuItem from './UserMenu/CustomUserMenuItem';
import { UserMenu, UserMenuItem } from './UserMenu/UserMenu';
import { ReactComponent as LogoutIcon } from './logout.svg';

interface UserHeaderMenu {
  user: User;
  userHeaderItems: FuryUserHeaderItem[];
  onLanguageModalClick: () => void;
  onNavigationMenuClick: () => void;
  onSignOutClick: () => void;
}

const UserHeaderMenu: React.FC<UserHeaderMenu> = (props) => {
  const { t } = useTranslation();

  return (
    <UserMenu className={styles.userMenu} title={t('Header.PROFILE')} onClick={props.onNavigationMenuClick}>
      <UserMenuItem key="username" untranslatedLabel={(props.user && props.user.preferred_username) || ''} />
      <UserMenuItem
        key="languagesetting"
        untranslatedLabel="Header.LANGUAGE_SWITCHER"
        onClick={props.onLanguageModalClick}
      />

      {props.userHeaderItems.map((item) => (
        <CustomUserMenuItem
          onClick={() => {
            document.body.appendChild(document.createElement(item.elementName));
          }}
          translationKey={`Header.${item.translationKey}`}
          key={item.translationKey}
        />
      ))}

      <UserMenuItem
        key="logout"
        untranslatedLabel="Header.LOGOUT"
        icon={<LogoutIcon />}
        onClick={props.onSignOutClick}
      />
    </UserMenu>
  );
};

interface HeaderProps {
  user: User | null;
  headerItems: FuryHeaderItem[];
  userHeaderItems: FuryUserHeaderItem[];
  appLinks: FuryApplicationLink[];
  pages: FuryPage[];

  activeMenuItem: string;

  showNavigationMenu: boolean;

  navigationMenuActive: boolean;
  setNavigationMenuActive: (newValue: boolean) => void;
  languageModelActive: boolean;
  setLanguageModalActive: (newValue: boolean) => void;

  onNavigationBarClick: () => void;
  onSignOutClick: () => void;
}

const Header: React.FC<HeaderProps> = (props) => (
  <header id="jh-header" className={styles.headerFlex}>
    {props.navigationMenuActive && (
      <NavigationMenu pages={props.pages} onClose={() => props.setNavigationMenuActive(false)} />
    )}
    <NavigationBar
      onLogoClick={(e) => {
        props.onNavigationBarClick();
        e.preventDefault();
      }}
      primaryElement={
        props.showNavigationMenu ? (
          <NavigationMenuButton
            activeMenuItem={props.activeMenuItem}
            active={props.navigationMenuActive}
            onClick={() => props.setNavigationMenuActive(!props.navigationMenuActive)}
          />
        ) : undefined
      }
      leftElements={
        <>
          {props.headerItems.map(({ elementName: CustomElement }) => (
            <CustomElement key={CustomElement} />
          ))}
        </>
      }
      rightElements={
        <>
          <AppSwitcher applicationLinks={props.appLinks} />
          {props.user !== null ? (
            <UserHeaderMenu
              user={props.user}
              userHeaderItems={props.userHeaderItems}
              onLanguageModalClick={() => props.setLanguageModalActive(true)}
              onNavigationMenuClick={() => props.setNavigationMenuActive(false)}
              onSignOutClick={props.onSignOutClick}
            />
          ) : undefined}
        </>
      }
    />

    {props.languageModelActive && (
      <LanguageModal
        onCancelClick={() => {
          props.setLanguageModalActive(false);
        }}
        onLanguageSaved={() => {
          props.setLanguageModalActive(false);
        }}
      />
    )}
  </header>
);

export default Header;
