import { useEffect, useState } from 'react';

type RequestedSubscriptions = Record<string, (sID: string) => void>;

interface Subscriptions {
  registeredServices: Set<string>;
  pendingSubscriptions: Map<string, [(string) => void]>;
}

const useSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<Subscriptions>({
    registeredServices: new Set(),
    pendingSubscriptions: new Map<string, [(string) => void]>(),
  });

  const addSubscriptionForService = (bundleId: string, subscriberCb: (id: string) => void) => {
    if (subscriptions.pendingSubscriptions.has(bundleId)) {
      const serviceSubscriptions = subscriptions.pendingSubscriptions.get(bundleId);
      serviceSubscriptions?.push(subscriberCb);
    } else {
      subscriptions.pendingSubscriptions.set(bundleId, [subscriberCb]);
    }
  };

  const addSubscriptions = (requestedSubscriptions?: RequestedSubscriptions): Map<string, [(string) => void]> => {
    if (requestedSubscriptions) {
      Object.keys(requestedSubscriptions).forEach((bundleId) =>
        addSubscriptionForService(bundleId, requestedSubscriptions[bundleId])
      );
    }

    return subscriptions.pendingSubscriptions;
  };

  const informPendingSubscribers = ({ registeredServices, pendingSubscriptions }: Subscriptions) => {
    registeredServices.forEach((bundleId) => {
      if (pendingSubscriptions.has(bundleId)) {
        pendingSubscriptions.get(bundleId)?.forEach((cb) => cb(bundleId));
        pendingSubscriptions.delete(bundleId);
      }
    });
  };

  const updateSubscriptions = (bundleId: string, requestedSubscriptions?: RequestedSubscriptions) => {
    setSubscriptions({
      registeredServices: subscriptions.registeredServices.add(bundleId),
      pendingSubscriptions: addSubscriptions(requestedSubscriptions),
    });
  };

  useEffect(() => {
    informPendingSubscribers(subscriptions);
  }, [subscriptions]);

  return { updateSubscriptions };
};

export default useSubscriptions;
