import { MsalProvider } from '@azure/msal-react';
import { FrontendComponentsProvider } from '@netfleets/frontend-components';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorBoundaryDisplay } from './components/ErrorBoundaryDisplay';
import { AuthenticationProvider } from './components/provider/AuthenticationProvider';
import { BundlesProvider } from './components/provider/BundlesProvider';
import { LanguageProvider } from './components/provider/LanguageProvider';
import { UserProvider } from './components/provider/UserProvider';
import msalInit from './shared/msalInit';

declare global {
  interface Window {
    React: unknown;
    ReactDOM: unknown;
    ReactDOMClient: unknown;
    dataLayer: unknown;
    gtag: unknown;
  }
}

(() => {
  window.React = React;
  window.ReactDOM = ReactDOM;
  window.ReactDOMClient = ReactDOMClient;
  window.dataLayer = window.dataLayer || [];
  window.gtag = () => {};
})();

const Application = (
  <LanguageProvider>
    <MsalProvider instance={await msalInit()}>
      <AuthenticationProvider>
        <BrowserRouter>
          <UserProvider>
            <BundlesProvider>
              <FrontendComponentsProvider applicationId="nf-fury">
                <ErrorBoundary FallbackComponent={ErrorBoundaryDisplay}>
                  <App />
                </ErrorBoundary>
              </FrontendComponentsProvider>
            </BundlesProvider>
          </UserProvider>
        </BrowserRouter>
      </AuthenticationProvider>
    </MsalProvider>
  </LanguageProvider>
);

const rootElement = document.querySelector<HTMLElement>('#root')!;

rootElement.style.display = 'flex';
rootElement.style.flexDirection = 'column';
rootElement.style.height = '100vh';

const root = createRoot(rootElement, { identifierPrefix: 'fury-main' });
root.render(Application);
