import { Color, Modal, Typography, Variant } from '@netfleets/frontend-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_LANGUAGES } from '../../shared/languageConfig';
import { logger } from '../../shared/logger';
import { useAuthentication } from '../provider/AuthenticationProvider';
import { useLanguage } from '../provider/LanguageProvider';
import styles from './LanguageModal.css';

interface SortedLanguage {
  key: string;
  label: string;
}

interface LanguageModalProps {
  onCancelClick: () => void;
  onLanguageSaved: () => void;
}

const LanguageModal: React.FC<LanguageModalProps> = (props) => {
  const { t } = useTranslation();
  const { language, saveLanguage, updateLanguage } = useLanguage();
  const [error, setError] = useState<string>();
  const { user } = useAuthentication();
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const sortedLanguages: SortedLanguage[] = Object.keys(AVAILABLE_LANGUAGES)
    .map((languageKey) => ({
      key: languageKey,
      label: AVAILABLE_LANGUAGES[languageKey],
    }))
    .sort((first, second) => first.label.localeCompare(second.label));

  const handleSaveUserLanguageAttempt = async () => {
    if (user?.idToken) {
      saveLanguage(selectedLanguage, user.idToken)
        .then((response) => {
          if (response.ok) {
            updateLanguage(selectedLanguage);
            props.onLanguageSaved();
          } else {
            setError(t('LanguageSwitcher.SAVE_ERROR'));
          }
        })
        .catch((err) => {
          setError(t('LanguageSwitcher.SAVE_ERROR'));
          logger.error(err);
        });
    }
  };

  return (
    <Modal
      title={t('LanguageSwitcher.LANGUAGE_SWITCHER')}
      width="sm"
      primaryButton={{
        text: t('LanguageSwitcher.LANGUAGE_SWITCHER_APPLY'),
        onClick: handleSaveUserLanguageAttempt,
      }}
      secondaryButton={{
        text: t('LanguageSwitcher.LANGUAGE_SWITCHER_CANCEL'),
        onClick: props.onCancelClick,
      }}
      errorMessage={error}
      onNotificationClosed={() => setError(undefined)}
    >
      <div className={styles.languageModalContent}>
        <label htmlFor="language-switcher" className={styles.languageSelectorLabel}>
          <Typography variant={Variant.LARGE_COPY} color={Color.BG10}>
            {t('LanguageSwitcher.LANGUAGE_SWITCHER_LABEL')}
          </Typography>
        </label>
        <select
          id="language-switcher"
          title={t('LanguageSwitcher.LANGUAGE_SWITCHER_LABEL')}
          defaultValue={language}
          onChange={(e) => setSelectedLanguage(e.target.value)}
          className={styles.languageSelector}
        >
          {sortedLanguages.map((l) => (
            <option key={l.key} value={l.key}>
              {l.label}
            </option>
          ))}
        </select>
      </div>
    </Modal>
  );
};

export default LanguageModal;
