import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from './Page';

interface PageMetadata {
  translationKey: string;
  elementName: string;
}

interface MicroSiteProps {
  page: PageMetadata;
}

const MicroSite: React.FC<MicroSiteProps> = (props) => {
  const { t } = useTranslation();

  return <Page title={t(`Apps.${props.page.translationKey}`)}>{React.createElement(props.page.elementName)}</Page>;
};

export default MicroSite;
