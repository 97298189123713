import React from 'react';
import { UserMenuItem } from './UserMenu';

interface CustomUserMenuItemProps {
  translationKey: string;
  onClick: () => void;
}

const CustomUserMenuItem: React.FC<CustomUserMenuItemProps> = ({ translationKey, onClick }) => (
  <UserMenuItem key={translationKey} untranslatedLabel={translationKey} onClick={onClick} />
);

export default CustomUserMenuItem;
