import { FALLBACK_LANGUAGE } from '@netfleets/fms-sdk';
import { i18nFormatFunctionOverwrite } from '@netfleets/frontend-components';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AVAILABLE_LANGUAGES } from '../shared/languageConfig';
import { DynamicImportPlugin } from './dynamicImportPlugin';

i18n
  .use(DynamicImportPlugin)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    supportedLngs: Object.keys(AVAILABLE_LANGUAGES),
    returnNull: false,
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: [
        'p',
        'b',
        'i',
        'u',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'br',
        'ul',
        'li',
        'table',
        'tr',
        'td',
        'th',
        'thead',
        'tbody',
      ],
    },
    interpolation: {
      alwaysFormat: true,
      format: i18nFormatFunctionOverwrite,
    },
  });

export default i18n;
