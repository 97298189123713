import { fury } from '@netfleets/fms-sdk';
import { FuryApplicationLink } from '@netfleets/fms-types';
import React, { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AppSwitcher.css';
import { ReactComponent as AppsIcon } from './AppSwitcherIcon.svg';

interface AppSwitcherProps {
  applicationLinks: FuryApplicationLink[];
}

export const AppSwitcher: React.FC<AppSwitcherProps> = ({ applicationLinks }) => {
  const { t } = useTranslation();
  const [showAppSwitcher, setShowAppSwitcher] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const appSwitcherId = useId();

  const handleClickOutside = (event) => {
    const isClickInside = ref.current?.contains(event.target);
    if (!isClickInside) {
      setShowAppSwitcher(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleAppSwitcher = () => {
    fury.trackEvent('APP_SWITCHER', 'TOGGLED');
    setShowAppSwitcher(!showAppSwitcher);
  };

  return (
    <div className={styles.appSwitcherContainer} ref={ref} title="AppSwitcher">
      {applicationLinks.length === 0 ? (
        <span className={`AppSwitcher ${styles.appSwitcherWithoutLinks}`} title={t('Header.APP_SWITCHER')}>
          {t('AppSwitcher.FLEETMANAGEMENT')}
        </span>
      ) : (
        <button
          // AppSwitcher class is used by the user journey tests
          className={`AppSwitcher ${styles.appSwitcher} ${showAppSwitcher ? styles.appSwitcherActive : ''}`}
          type="button"
          title={t('Header.APP_SWITCHER')}
          onClick={toggleAppSwitcher}
          id={appSwitcherId}
          aria-label={t('Header.APP_SWITCHER')}
          aria-haspopup="true"
          aria-controls="menu"
          aria-expanded={showAppSwitcher}
        >
          <span>{t('AppSwitcher.FLEETMANAGEMENT')}</span>
          <div className={`${styles.appsMenuButton} ${showAppSwitcher ? styles.appsMenuButtonActive : ''}`}>
            <AppsIcon />
          </div>
        </button>
      )}

      {showAppSwitcher && (
        <div className={styles.wrapper}>
          <ul className={styles.wrapperLinks} role="menu" aria-labelledby={appSwitcherId}>
            {applicationLinks.map((applicationLink) => (
              <li key={applicationLink.translationKey}>
                <a href={applicationLink.targetUrl} rel="noopener">
                  {t(`AppSwitcher.${applicationLink.translationKey}`)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AppSwitcher;
