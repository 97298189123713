import React, { useEffect } from 'react';
import { SITE_TITLE } from '../shared/config';
import styles from './Page.css';

interface PageProps {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

const Page: React.FC<PageProps> = (props) => {
  const siteTitle = props.title ? `${props.title} | ${SITE_TITLE}` : SITE_TITLE;

  useEffect(() => {
    document.title = siteTitle;
  }, [siteTitle]);

  return <div className={`page-container ${styles.containerFlex} ${props.className || ''}`}>{props.children}</div>;
};

export default Page;
