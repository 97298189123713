import { useLocalStorage } from '@netfleets/frontend-components';
import { TileConfiguration } from '../../shared/tileConfig';

export const useTileConfigurations = (defaultTileConfigurations: TileConfiguration[]) => {
  if (
    !localStorage.getItem('fury.tileConfigurationVersion') ||
    localStorage.getItem('fury.tileConfigurationVersion') !== '1'
  ) {
    localStorage.removeItem('fury.tileConfigurations');
    localStorage.setItem('fury.tileConfigurationVersion', '1');
  }

  /**
   * Compare the persisted tileConfiguration from localStorage to the defaultTileConfigurations from fury.
   * If there are new tiles in the defaultTileConfigurations we add them to the persisted config as visible by default
   *
   * @returns {TileConfiguration[]} - A new array of tile configurations that contains all the tiles from the source and target configurations, with no duplicates.
   */
  const updatePersistedTileConfigurations = (): TileConfiguration[] => {
    const sourceIds = new Set(tileConfigurations.map((config) => config.id));
    const updatedConfigurations = [...tileConfigurations];

    for (const tileConfiguration of defaultTileConfigurations) {
      if (!sourceIds.has(tileConfiguration.id)) {
        updatedConfigurations.push({ ...tileConfiguration });
      }
    }

    return updatedConfigurations;
  };

  const [tileConfigurations, persistTileConfigurations] = useLocalStorage('fury.tileConfigurations', {
    initialValue: defaultTileConfigurations,
  });

  const newTileConfigurations = updatePersistedTileConfigurations();
  if (newTileConfigurations.length !== tileConfigurations.length) {
    persistTileConfigurations(newTileConfigurations);
  }

  return {
    tileConfigurations,
    persistTileConfigurations,
  };
};
