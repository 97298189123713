import { isFeatureFlagSet } from '@netfleets/frontend-internal';
import { SESSION_LOGGING_FLAG } from './config';
import { logger } from './logger';

const FURY_SESSION_TOKEN_KEY = 'fury.accessToken';

export function setSessionToken(token: string): void {
  sessionStorage.setItem(FURY_SESSION_TOKEN_KEY, token);

  if (isFeatureFlagSet(SESSION_LOGGING_FLAG)) {
    logger.info('Session token updated');
  }
}

export function getSessionToken(): string {
  return sessionStorage.getItem(FURY_SESSION_TOKEN_KEY) || '';
}

export function removeSessionToken(): void {
  sessionStorage.removeItem(FURY_SESSION_TOKEN_KEY);

  if (isFeatureFlagSet(SESSION_LOGGING_FLAG)) {
    logger.info('Session token removed');
  }
}
