import { RefObject } from 'react';

const keyframes = [
  { offset: 0, opacity: 1, backgroundPosition: '0 0' },
  { offset: 0.06, opacity: 1, backgroundPosition: '0 0' },
  { offset: 0.36, opacity: 1, backgroundPosition: '0 100%' },
  { offset: 0.62, opacity: 0, backgroundPosition: '0 100%' },
  { offset: 1, opacity: 0, backgroundPosition: '0 100%' },
];

const animationId = 'fury-overview-tile-skeleton-loader';

const getAnimationProgressFromBrowser = () => {
  const other = document.getAnimations().filter((a) => a.id === animationId)[0];
  if (other && other.effect) {
    return other.effect.getComputedTiming().progress ?? 0;
  }

  return 0;
};

export const useAnimations = () => {
  const startAnimation = (animationRef: RefObject<HTMLDivElement>) => {
    animationRef.current?.animate(keyframes, {
      duration: 2120,
      iterations: Infinity,
      id: animationId,
      iterationStart: getAnimationProgressFromBrowser(),
    });
  };

  return { startAnimation };
};

export default useAnimations;
