/* eslint-disable no-console */
import { FuryBundleEvent } from '@netfleets/fms-types';
import bundles from './bundles';

export const logger = {
  info: (message: string, ...optionalParams: unknown[]) => {
    if (typeof jest !== 'undefined') {
      return;
    }

    console.info('[fury]', message, ...optionalParams);
  },
  warn: (message: string, ...optionalParams: unknown[]) => {
    if (typeof jest !== 'undefined') {
      return;
    }

    console.warn('[fury]', message, ...optionalParams);
  },
  error: (message: string, ...optionalParams: unknown[]) => {
    if (typeof jest !== 'undefined') {
      return;
    }

    console.error('[fury]', message, ...optionalParams);
  },
};

const startTime = performance.now();

export function logBundle(details: Partial<FuryBundleEvent>) {
  try {
    // no other way of getting the calling script name
    const scriptName = new Error()?.stack?.split(' at ').pop();
    const loadingTime = ((performance.now() - startTime) / 1000).toFixed(2);

    const bundle = details.bundleId || scriptName;

    if (!details.bundleId) {
      logger.warn(
        `bundle from '${scriptName}' is not using the new fury event with a bundleId and was loaded in ${loadingTime}sec`,
        details
      );
    } else {
      logger.info(`bundle '${bundle}' from '${scriptName}' loaded after ${loadingTime}sec`, details);

      if (!bundles.some((bundle) => bundle.id === details.bundleId)) {
        logger.warn(`bundle '${bundle}' is not a known bundle`);
      }
    }
  } catch (e) {
    logger.error('error in logBundle', e);
  }
}
