import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../shared/logger';
import { useAuthentication } from './AuthenticationProvider';
import { useLanguage } from './LanguageProvider';

interface UserContext {
  hasFleetManagementPermission: boolean | undefined;
}

export const UserContext = createContext<UserContext>({
  hasFleetManagementPermission: false,
});

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { language, saveLanguage, updateLanguage } = useLanguage();
  const { user } = useAuthentication();
  const navigate = useNavigate();

  const [hasFleetManagementPermission, setFleetManagementPermission] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (user?.idToken === undefined) {
      return;
    }

    const fetchCurrentUser = async () => {
      const response = await fetch(`/korg/api/users/me`, {
        headers: new Headers({
          Authorization: `Bearer ${user.idToken}`,
        }),
      });
      setFleetManagementPermission(response.status !== 403);

      if (response.ok) {
        const data = await response.json();
        if (data.selectedLanguage === '') {
          // We do not handle the error here. We will simply retry on next reload
          saveLanguage(language, user.idToken).catch(logger.error);
        } else if (data.selectedLanguage !== language) {
          updateLanguage(data.selectedLanguage);
        }

        if (!data.lastSeen) {
          navigate('/_/welcome');
        }
      }
    };

    fetchCurrentUser().catch(() => {
      setFleetManagementPermission(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.idToken]);

  return (
    <UserContext.Provider
      value={{
        hasFleetManagementPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
