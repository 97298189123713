import { FuryToolbarItem } from '@netfleets/fms-types';
import React from 'react';

interface ToolbarProps {
  toolbarItems: FuryToolbarItem[];
}

const Toolbar: React.FC<ToolbarProps> = ({ toolbarItems }) => (
  <>
    {toolbarItems.map(({ elementName: CustomElement }) => (
      <CustomElement key={CustomElement} />
    ))}
  </>
);

export default Toolbar;
