import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NavigationMenuLink.css';

interface NavigationMenuLinkProps {
  text: string;
  href: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
  isExtern?: boolean;
  onClick?: () => void;
}

const NavigationMenuLink: React.FC<NavigationMenuLinkProps> = (props) => (
  <Link
    className={`${props.isSmall ? styles.small : styles.link} ${props.isActive && styles.active}`}
    to={props.href}
    aria-disabled={props.isDisabled}
    onClick={props.onClick}
    target={props.isExtern ? '_blank' : undefined}
    rel={props.isExtern ? 'noopener noreferrer' : undefined}
  >
    {props.text}
  </Link>
);

export default NavigationMenuLink;
