import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { colorBlueGray10, DragIcon, InvisibleIcon, VisibleIcon } from '@netfleets/frontend-components';
import React from 'react';
import styles from './Tile.css';
import TilesSkeletonLoader from './TilesSkeletonLoader';

export interface TileProps {
  id: string;
  elementName: string;
  loading: boolean;
  hidden?: boolean;
  editMode?: boolean;
  onToggleVisibility: (customElement: string) => void;
  children: React.ReactElement<PlainTileProps>;
}

export const Tile: React.FC<TileProps> = ({ id, loading, editMode, hidden = false, onToggleVisibility, children }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
    disabled: !editMode,
  });

  const classes = `
  ${styles.tile}
  ${editMode ? styles.editMode : ''}
  ${loading ? styles.hideElement : ''}
  ${editMode && hidden ? styles.hidden : ''}
  `;

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: loading ? 'wait' : isDragging ? 'grabbing' : 'grab',
  };

  // TODO NF-4158: cleanup needed. Especially the labels
  return (
    <div
      className={`${styles.tileContainer} ${editMode ? styles.editMode : ''}`}
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <div
        data-testid="overview-tile"
        aria-label="overview-tile"
        id={id}
        className={classes}
        aria-busy={loading}
        style={{
          ...(isDragging && { backgroundColor: colorBlueGray10, transition: 'background-color 0.25s ease-in-out' }),
        }}
      >
        <div className={styles.editModeWrapper}>
          <div className={styles.tileWrapper} {...listeners}>
            {children}
          </div>
          {editMode && (
            <div className={styles.editModeOverlay}>
              <div className={styles.controls} style={{ ...(isDragging && { visibility: 'hidden' }) }}>
                <button aria-label={hidden ? 'Show tile' : 'Hide tile'} onClick={() => onToggleVisibility(id)}>
                  {hidden ? <InvisibleIcon /> : <VisibleIcon />}
                </button>
                <button
                  aria-label="Grab tile"
                  className={styles.drag}
                  {...listeners}
                  style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                >
                  <DragIcon />
                </button>
              </div>
              <div className={styles.grabArea} {...listeners} style={{ cursor: isDragging ? 'grabbing' : 'grab' }} />
            </div>
          )}
        </div>
      </div>
      {loading && <TilesSkeletonLoader />}
    </div>
  );
};

export interface PlainTileProps {
  elementName: string;
}

export const PlainTile = React.memo<PlainTileProps>(
  function PlainTile({ elementName: CustomElement }) {
    return <CustomElement />;
  },
  (prevProps, nextProps) => prevProps.elementName === nextProps.elementName
);
