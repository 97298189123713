import bundleConfiguration, { Bundle } from './bundles';

declare global {
  interface Window {
    fury: {
      config: {
        ENVIRONMENT: string;
        AZURE_TENANT_HOST: string;
        AZURE_DOMAIN: string;
        C4S_ENDPOINT: string;
        OIDC_CLIENT_ID: string;
        FLEETMANAGEMENT_ENDPOINT: string;
        BUNDLES_OVERRIDE?: Bundle[];
        BACKEND_API_DOCUMENTATION: string;
      };
      flag: {
        ENABLE_EXTRA_LANGUAGES: boolean;
      };
    };
  }
}

export const FURY_ROUTE_PREFIX = '/_';
export const CALLBACK_PATH = `${FURY_ROUTE_PREFIX}/callback`;

export const SITE_TITLE = 'Jungheinrich';

const { protocol, hostname } = window.location;
const port = window.location.port ? `:${window.location.port}` : '';
export const BASE_URL = `${protocol}//${hostname}${port}`;
export const REDIRECT_URI = `${BASE_URL}${CALLBACK_PATH}`;

export const { ENVIRONMENT, AZURE_DOMAIN, AZURE_TENANT_HOST, OIDC_CLIENT_ID: AZURE_CLIENT_ID } = window.fury.config;

export const AZURE_MICROSOFT_AUTHENTICATION_NO_CONSENT_ERROR_REGEX = /AADSTS65004/;

// BUNDLES_OVERRIDE is used to provide override the bundles from outside the fury container
export const BUNDLES: Bundle[] = window.fury.config.BUNDLES_OVERRIDE || bundleConfiguration;

export const SESSION_LOGGING_FLAG = 'fury.session_logging';
