import { Accordion, Color, Panel, Typography, Variant } from '@netfleets/frontend-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../components/Page';
import styles from './Imprint.css';

interface ImprintTranslations {
  SECTIONS: Record<
    string,
    {
      HEADER: string;
      CONTENT: string;
    }
  >;
}

const Imprint: React.FC = () => {
  const { t, i18n } = useTranslation();

  const rawTranslations = i18n.getDataByLanguage(i18n.language);
  let rawSections: ImprintTranslations['SECTIONS'] = {};
  if (rawTranslations && 'translation' in rawTranslations) {
    if ('Imprint' in rawTranslations.translation) {
      const it = rawTranslations.translation.Imprint as unknown as ImprintTranslations;
      rawSections = it.SECTIONS;
    }
  }

  const rawTranslationsToDisplayableArray = (translationObject: ImprintTranslations['SECTIONS']) =>
    Object.keys(translationObject).reduce((acc: ImprintTranslations['SECTIONS'][string][], key) => {
      acc.push(translationObject[key]);
      return acc;
    }, []);

  return (
    <Page title={t('Imprint.TITLE')} className={styles.page}>
      <div>
        <Typography variant={Variant.H3} color={Color.BG10}>
          {t('Imprint.TITLE')}
        </Typography>
        <Panel>
          {rawTranslationsToDisplayableArray(rawSections).map(({ HEADER }, index) => (
            <Accordion key={HEADER} header={t(`Imprint.SECTIONS.${index}.HEADER`)}>
              <Typography variant={Variant.COPY} color={Color.BG10}>
                <Trans i18nKey={`Imprint.SECTIONS.${index}.CONTENT`} />
              </Typography>
            </Accordion>
          ))}
        </Panel>
      </div>
    </Page>
  );
};

export default Imprint;
