interface MsalLogEntry {
  timestamp: Date;
  level: string;
  message: string;
  correlationId?: string;
}

type SortOrder = 'asc' | 'desc';

class MsalLogParser {
  private static readonly LOG_PATTERN = /\[(.*?)] : \[(.*?)] : (.*?) : (\w+)(?:\s*\[(\w+)])? - (.*)/;

  static parse(logLine: string): MsalLogEntry | null {
    const match = logLine.match(this.LOG_PATTERN);
    if (!match) return null;
    const [, timestamp, correlationId, , level, , message] = match;
    return {
      timestamp: new Date(timestamp),
      level: level,
      message: message.trim(),
      correlationId: correlationId,
    };
  }
}

class MsalLogger {
  private logs: MsalLogEntry[] = [];

  addLog(logLine: string): void {
    const parsedLog = MsalLogParser.parse(logLine);

    if (parsedLog) {
      this.logs.push(parsedLog);
    }
  }

  getLogs(sortOrder: SortOrder = 'desc'): MsalLogEntry[] {
    return sortOrder === 'asc' ? this.logs : this.logs.toReversed();
  }
}

export { MsalLogEntry, MsalLogParser, MsalLogger, SortOrder };

export const msalLogger = new MsalLogger();
