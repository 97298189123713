import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { isFeatureFlagSet } from '@netfleets/frontend-internal';
import { AZURE_CLIENT_ID, AZURE_DOMAIN, AZURE_TENANT_HOST, BASE_URL } from './config';
import { msalLogger } from './msalLogger';

const policy = isFeatureFlagSet('testPolicy') ? 'B2C_1A_SIGNUP_SIGNIN_FMS_RELEASE' : 'B2C_1A_SIGNUP_SIGNIN_FMS';

async function msalInit() {
  const configuration: Configuration = {
    auth: {
      clientId: AZURE_CLIENT_ID,
      authority: `https://${AZURE_TENANT_HOST}/${AZURE_DOMAIN}/${policy}`,
      knownAuthorities: [AZURE_TENANT_HOST],
      redirectUri: `${BASE_URL}/`,
      postLogoutRedirectUri: `${BASE_URL}/`,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }

          msalLogger.addLog(message);
        },
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: false,
      },
    },
  };

  const client = new PublicClientApplication(configuration);
  await client.initialize();
  return client;
}

export default msalInit;
