import React, { useEffect, useRef } from 'react';
import styles from './TilesSkeletonLoader.css';
import { useAnimations } from './useAnimations';

interface TilesSkeletonLoaderProps {
  hideAnimation?: boolean;
}

const TilesSkeletonLoader: React.FC<TilesSkeletonLoaderProps> = ({ hideAnimation }) => {
  const animationRef = useRef<HTMLDivElement>(null);
  const { startAnimation } = useAnimations();

  useEffect(() => {
    if (!hideAnimation) {
      startAnimation(animationRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="overview-tile-skeleton-loader" className={styles.wrapper}>
      <div className={styles.skeleton} />
      {!hideAnimation && <div className={`${styles.skeleton}`} ref={animationRef} />}
    </div>
  );
};

export default TilesSkeletonLoader;
