import React, { Children, cloneElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserMenu.css';

import { ReactComponent as UserIcon } from './user.svg';

interface UserMenuItemProps {
  untranslatedLabel: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}
export const UserMenuItem: React.FC<UserMenuItemProps> = (props) => {
  const { t } = useTranslation();

  return (
    <li
      className={props.onClick ? styles.clickable : ''}
      onClick={() => props.onClick && props.onClick()}
      key={props.untranslatedLabel}
    >
      {t(props.untranslatedLabel)}
      {props.icon && props.icon}
    </li>
  );
};

export const UserMenu = ({ children, title, onClick, className = '' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickOutside = (event) => {
    const isClickInside = ref.current?.contains(event.target);
    if (!isClickInside) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickForChildren = (child) => {
    if (child && child.props && child.props.onClick) {
      return cloneElement(child, {
        onClick: () => {
          setOpen(false);
          child.props.onClick();
        },
      });
    }

    return child;
  };

  // jh-user-menu and user-button are used by the user journey tests
  return (
    <div className={`jh-user-menu ${styles.menu} ${className}`} ref={ref}>
      <button
        type="button"
        className={`user-button ${styles.button} ${open ? styles.buttonActive : ''}`}
        onClick={() => {
          setOpen(!open);
          onClick();
        }}
        title={title}
      >
        <UserIcon />
      </button>
      {open && <ul>{Children.map(children, handleClickForChildren)}</ul>}
    </div>
  );
};
