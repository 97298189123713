import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import React, { Children, FC, useState } from 'react';
import { TileProps } from '../tiles/Tile';
import styles from './EditModeWrapper.css';

type GridProps = {
  children: React.ReactElement<TileProps>[];
  columns: number;
  editMode: boolean;
  gap?: number;
};

const Grid: FC<GridProps> = ({ children, editMode, columns, gap }) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>(undefined);

  useDndMonitor({
    onDragOver(event) {
      setActiveId(event.over?.data?.current?.sortable.index);
    },
    onDragEnd() {
      setActiveId(undefined);
    },
    onDragCancel() {
      setActiveId(undefined);
    },
  });

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, minmax(28rem, 1fr))`,
        gridGap: gap || 0,
      }}
    >
      {Children.map(children, (child, key) => (
        <div
          key={child.props.elementName}
          id={`grid-cell-${child.props.elementName}`}
          className={`${styles.gridCell} ${editMode ? styles.editMode : ''} ${key === activeId ? styles.activeOver : ''}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default Grid;
