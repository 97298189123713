import { FALLBACK_LANGUAGE } from '@netfleets/fms-sdk';
import i18n, { BackendModule } from 'i18next';
import { logger } from '../shared/logger';

export const DynamicImportPlugin: BackendModule = {
  type: 'backend',
  init: function () {},
  read: function (language, namespace, callback) {
    // Load all translations dynamically, but do not issue new http requests, but bundle the translations instead
    // Webpack annotations are important, so that no remote but embedded bundles are created!
    import(/* webpackMode: "eager" */ /* webpackPrefetch: true */ `./translation/${language}.json`)
      .then((obj) => {
        callback(null, obj);
      })
      .catch((e) => {
        // Needed to handle e.g. when a new language gets added but some team does not support it yet
        logger.error(`"${language}" could not be loaded. Switching to fallback language instead...`);
        i18n.changeLanguage(FALLBACK_LANGUAGE);
        logger.error(e);
      });
  },
};
