import { Color, Panel, Switch, TextLink, Typography, Variant, Weight } from '@netfleets/frontend-components';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import { useLanguage } from '../components/provider/LanguageProvider';
import styles from './PrivacyPreferences.css';

const PrivacyPreferences: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isTrackingEnabled = window._etracker?.isTrackingEnabled;
  const enableTracking = window._etracker?.enableTracking;
  const disableTracking = window._etracker?.disableTracking;
  const [trackingEnabled, setTrackingEnabled] = useState(isTrackingEnabled !== undefined && isTrackingEnabled());

  const privacyNoteURL = language?.startsWith('de')
    ? 'https://www.etracker.com/datenschutz/'
    : 'https://www.etracker.com/en/data-privacy/';

  const onSwitchChange = () => {
    if (trackingEnabled && disableTracking !== undefined) {
      setTrackingEnabled(false);
      disableTracking();
    } else if (!trackingEnabled && enableTracking !== undefined) {
      setTrackingEnabled(true);
      enableTracking();
    }
  };

  return (
    <Page title={t('PrivacyPreferences.TITLE')} className={styles.page}>
      <div>
        <Typography variant={Variant.H3} color={Color.BG10}>
          {t('PrivacyPreferences.TITLE')}
        </Typography>
        <Panel>
          <div className={styles.section}>
            <Typography variant={Variant.H6} color={Color.BG10}>
              {t('PrivacyPreferences.SECTIONS.ETRACKER.HEADER')}
            </Typography>
            <div className={styles.content}>
              <Typography variant={Variant.COPY} color={Color.BG10}>
                <Trans i18nKey={'PrivacyPreferences.SECTIONS.ETRACKER.CONTENT'} />{' '}
                <TextLink customAnchorElement={<Link to={privacyNoteURL} target={'_blank'} rel={'noreferrer'} />}>
                  {privacyNoteURL}
                </TextLink>
              </Typography>
            </div>
            <Switch
              id="etracker-opt-out"
              isChecked={trackingEnabled}
              onChange={onSwitchChange}
              label={
                <>
                  <Typography variant={Variant.COPY} color={Color.BG10} weight={Weight.NORMAL}>
                    {t('PrivacyPreferences.SECTIONS.ETRACKER.SWITCH_TEXT')}
                  </Typography>
                  <Typography variant={Variant.CAPTION} color={Color.BG10}>
                    {t('PrivacyPreferences.SECTIONS.ETRACKER.SWITCH_HINT')}
                  </Typography>
                </>
              }
            />
          </div>
        </Panel>
      </div>
    </Page>
  );
};

export default PrivacyPreferences;
