import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FURY_ROUTE_PREFIX } from '../../shared/config';
import { useFuryLocation } from '../hooks/useFuryLocation';
import { useAuthentication } from '../provider/AuthenticationProvider';
import { useBundles } from '../provider/BundlesProvider';
import { useUser } from '../provider/UserProvider';
import Header from './Header';

const HeaderContainer: React.FC = () => {
  const { pages, headerItems, applicationLinks, userHeaderItems } = useBundles();
  const navigate = useNavigate();
  const { user, signOut } = useAuthentication();
  const { hasFleetManagementPermission } = useUser();
  const [languageModelActive, setLanguageModalActive] = useState<boolean>(false);
  const [navigationMenuActive, setNavigationMenuActive] = useState<boolean>(false);
  const location = useFuryLocation();
  const { t } = useTranslation();

  let activePageTitle = t('Header.MENU');
  const activePage = location ? pages.find((p) => p.route === location.baseRoute) : undefined;
  if (!activePage) {
    if (location.pathname === '/') {
      activePageTitle = t('Apps.DASHBOARD');
    } else if (`${FURY_ROUTE_PREFIX}/imprint` === location.baseRoute) {
      activePageTitle = t('Apps.IMPRINT');
    } else if (`${FURY_ROUTE_PREFIX}/privacy` === location.baseRoute) {
      activePageTitle = t('Apps.PRIVACY_PREFERENCES');
    }
  } else {
    activePageTitle = t(`Apps.${activePage.translationKey}`);
  }

  const appLinks =
    user !== null && hasFleetManagementPermission
      ? applicationLinks
      : [
          {
            translationKey: 'CALL4SERVICE',
            targetUrl: window.fury.config.C4S_ENDPOINT,
            index: -1,
          },
        ];

  const showNavigationMenu: boolean = user !== null && Boolean(hasFleetManagementPermission);
  const handleNavigationBarClick = () => navigate('/');

  return (
    <Header
      activeMenuItem={activePageTitle}
      user={user}
      userHeaderItems={userHeaderItems}
      headerItems={headerItems}
      appLinks={appLinks}
      pages={pages}
      showNavigationMenu={showNavigationMenu}
      languageModelActive={languageModelActive}
      setLanguageModalActive={setLanguageModalActive}
      navigationMenuActive={navigationMenuActive}
      setNavigationMenuActive={setNavigationMenuActive}
      onNavigationBarClick={handleNavigationBarClick}
      onSignOutClick={signOut}
    />
  );
};

export default HeaderContainer;
